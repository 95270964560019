
import Vue from "vue";
export default Vue.extend({
  name: "DemoLogin",
  data() {
    return {
      token: "",
    };
  },
  methods: {
    valueChange() {
      this.$emit("dataChanged", "token", this.token);
    },
  },
});
