
import { mapGetters } from "vuex";
import Vue from "vue";
import Modal from "./plugins/modal/Modal.vue";
import i18n from "./plugins/i18n";
import NewUserIntro from "./components/NewUserIntro.vue";
import DemoLogin from "@/components/DemoLogin.vue";
import axios from "axios";
import sessionStore from "./store/sessionStore";

export default Vue.extend({
  components: { Modal, NewUserIntro },
  name: "App",

  data() {
    const lang = window.navigator.language.split("-")[0];
    return {
      language: Object.keys(i18n.messages).indexOf(lang),
      isDarkMode: true,
    };
  },
  created() {
    i18n.locale = window.navigator.language.split("-")[0];
    this.$vuetify.lang.current = window.navigator.language.split("-")[0];
    if (localStorage.getItem("isDarkMode") == null) {
      localStorage.setItem("isDarkMode", "true");
    }

    this.$vuetify.theme.dark = localStorage.getItem("isDarkMode") == "true";

    this.$store.dispatch("SessionStore/storeLogin");

    // if (localStorage.getItem("isDarkMode") == null) {
    //   this.$vuetify.theme.currentTheme
    // } else {
    // }
  },
  computed: {
    ...mapGetters({
      loggedIn: "SessionStore/loggedIn",
      isNewUser: "SessionStore/isNewUser",
    }),
    isStillLoading(): boolean {
      return this.loggedIn === undefined && this.isNewUser === undefined;
    },
    languages() {
      const value = Object.keys(i18n.messages);
      return value;
    },
  },
  methods: {
    demoLogin() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that = this;
      this.$modal.show({
        customComponentConfig: {
          data: {},
          vueConstructor: DemoLogin,
        },
        title: "Demo login",
        buttons: [
          {
            color: "success",
            icon: "fa-check",
            text: "OK",
            async onClick(data) {
              try {
                const response = await axios.post<{
                  jwt: string;
                  success: boolean;
                }>("/api/auth/twitch/demoLogin", data);
                localStorage.setItem("jwt", response.data.jwt);
                that.$store.dispatch("SessionStore/storeLogin");
              } catch (error) {
                console.error(error);
              }
              return true;
            },
          },
        ],
      });
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("isDarkMode", `${this.$vuetify.theme.dark}`);
    },
    setLanguage(lang: string) {
      i18n.locale = lang;
    },
    async forwardToTwitch() {
      const result = await this.axios.get("/api/auth/twitch/getUrl");
      window.location.href = result.data;
    },
  },
});
