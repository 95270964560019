
import BannedKeywordsCard from "@/components/BannedKeywordsCard.vue";
import SongrequestSettings from "@/components/SongrequestSettings.vue";
import SpotifyConnectCard from "@/components/SpotifyConnectCard.vue";
import i18n from "@/plugins/i18n";
import Vue from "vue";
export default Vue.extend({
  components: { SpotifyConnectCard, SongrequestSettings, BannedKeywordsCard },
  name: "HomeView",
  created() {
    this.$store.dispatch("SessionStore/loginSpotify");
    this.$store.dispatch("SessionStore/getSettings");
  },
  data() {
    return {
      requestQuery: "",
    };
  },
  methods: {
    valueChange() {
      this.axios.post("/api/twitch/requestSongDemo", {
        query: this.requestQuery,
      });
    },
  },
});
